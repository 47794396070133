import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomePage-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'fr', json: 'home' },
    },
    {
        path: '/en',
        name: 'HomePage-en',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'en', json: 'home' },
    },
    {
        path: '/evenements',
        name: 'EvenementsListing-fr',
        component: () =>
            import(/* webpackChunkName: "evenements-listing" */ '@/views/evenements/Index.vue'),
        meta: { lang: 'fr', json: 'evenements', footer: 'light' },
    },
    {
        path: '/en/events',
        name: 'EvenementsListing-en',
        component: () =>
            import(/* webpackChunkName: "evenements-listing" */ '@/views/evenements/Index.vue'),
        meta: { lang: 'en', json: 'evenements', footer: 'light' },
    },
    {
        path: '/evenements/:slug',
        name: 'Evenements-fr',
        component: () =>
            import(/* webpackChunkName: "evenements" */ '@/views/evenements/Entry.vue'),
        meta: { lang: 'fr', json: 'evenementsEntry', footer: 'light', slug: true },
    },
    {
        path: '/en/events/:slug',
        name: 'Evenements-en',
        component: () =>
            import(/* webpackChunkName: "evenements" */ '@/views/evenements/Entry.vue'),
        meta: { lang: 'en', json: 'evenementsEntry', footer: 'light', slug: true },
    },
    {
        path: '/notre-offre',
        name: 'NotreOffre-fr',
        component: () => import(/* webpackChunkName: "notreOffre" */ '../views/NotreOffre.vue'),
        meta: { lang: 'fr', json: 'notreOffre' },
    },
    {
        path: '/en/our-offer',
        name: 'NotreOffre-en',
        component: () => import(/* webpackChunkName: "notreOffre" */ '../views/NotreOffre.vue'),
        meta: { lang: 'en', json: 'notreOffre' },
    },
    {
        path: '/soumission',
        name: 'Soumission-fr',
        component: () => import(/* webpackChunkName: "soumission" */ '../views/Soumission.vue'),
        meta: { lang: 'fr', json: 'soumission', navBack: true },
    },
    {
        path: '/en/quote',
        name: 'Soumission-en',
        component: () => import(/* webpackChunkName: "soumission" */ '../views/Soumission.vue'),
        meta: { lang: 'en', json: 'soumission', navBack: true },
    },
    {
        path: '/politique-de-confidentialite',
        name: 'Politique-fr',
        component: () => import(/* webpackChunkName: "politique" */ '../views/Politique.vue'),
        meta: { lang: 'fr', json: 'politiqueDeConfidentialite', navBack: true },
    },
    {
        path: '/en/privacy',
        name: 'Politique-en',
        component: () => import(/* webpackChunkName: "politique" */ '../views/Politique.vue'),
        meta: { lang: 'en', json: 'politiqueDeConfidentialite', navBack: true },
    },
    {
        path: '*',
        name: 'p404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        meta: { lang: 'en', json: 'home' },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
})

router.beforeEach((to, from, next) => {
    const { lang } = to.meta
    document.documentElement.lang = lang
    i18n.locale = lang

    if (Store.getters['locale'] !== lang) {
        Store.dispatch('getGlobals')
        Store.dispatch('setLocale', lang)
    }

    setTimeout(() => {
        Store.dispatch('resetContent')

        let type = 'pages'
        const { meta, params } = to
        let slug = meta.json
        let item = ''

        if (meta.slug) {
            item = params.slug
            type = '' // Use slug and ignore type
        }

        Store.dispatch('getContent', { lang: meta.lang, slug, type, item })
    }, 1)

    next()
})

export default router
