<template>
    <div>
        <!-- Main nav -->
        <nav class="main-nav site-max-width" @mouseout="delayCloseActiveLink">
            <router-link :to="{ name: 'HomePage-' + $route.meta.lang }" class="logo" v-if="!$route.meta.navBack">
                <img src="@/assets/img/logo-dock619.png" alt="Dock619" />
            </router-link>
            <router-link :to="{ name: 'HomePage-' + $route.meta.lang }" class="logo back-btn" v-else>
                <svg height="22" viewBox="0 0 47 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M21.2685 31.3417L9.97694 20.1125L47 20.1125L47 14.8873L9.97694 14.8873L21.2685 3.65815L17.5908 -1.28552e-06L-7.64949e-07 17.5L17.5908 35L21.2685 31.3417Z"
                        fill="#000000"
                    />
                </svg>
                {{ $t('PAGE D’ACCUEIL') }}
            </router-link>

            <div class="nav-links">
                <router-link :to="{ name: 'NotreOffre-' + $route.meta.lang }" class="nav-link color-1">{{ $t('Notre offre') }}</router-link>
                <a
                    href="#"
                    class="nav-link with-sublinks color-3"
                    @mouseover.stop="linkHoverActive('Événements')"
                >
                    <span @click.prevent="goToPageEvenements()">{{ $t('Événements') }}</span>
                    <ul
                        class="sublinks"
                        :class="{ 'is-active': linkActive === 'Événements' }"
                        v-if="globalsIsLoaded"
                    >
                        <svg
                            height="14"
                            viewBox="0 0 22 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.5618 0.995983C11.761 -0.0211118 10.2197 -0.0211109 9.4189 0.995984L0.54857 12.2628C-0.484334 13.5748 0.450227 15.5 2.12 15.5L19.8607 15.5C21.5304 15.5 22.465 13.5748 21.4321 12.2628L12.5618 0.995983Z"
                                fill="#009675"
                            />
                        </svg>
                        <li v-for="(item, i) in globals.evenementsEntry.data" :key="i">
                            <router-link class="sublink" :to="item.page.jsonUrl">{{
                                item.page.title
                            }}</router-link>
                        </li>
                    </ul>
                </a>
                <span class="separator">|</span>
                <a v-if="pageIsLoaded && $route.meta.lang === 'fr'" :href="content.page.urls['en']" class="nav-link lang color-2">ENG</a>
                <a v-else-if="pageIsLoaded && $route.meta.lang === 'en'" :href="content.page.urls['fr']" class="nav-link lang color-2">FR</a>
                <a v-else :href="'#'" class="nav-link lang color-2">&nbsp;</a>
                <router-link :to="{ name: 'Soumission-' + $route.meta.lang }" class="site-btn"> {{ $t('Soumission') }} </router-link>

                <div
                    id="hamburger"
                    @click.prevent.stop="
                        () => {
                            mobileMenuOpen = !mobileMenuOpen
                        }
                    "
                    :class="{ open: mobileMenuOpen }"
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </nav>

        <!-- Mobile Menu -->
        <div
            class="mobile-menu-wrap"
            :class="{ open: mobileMenuOpen }"
            @click="closeAfterClick()"
            v-if="globalsIsLoaded"
        >
            <nav>
                <router-link
                    :to="{ name: 'NotreOffre-' + $route.meta.lang }"
                    class="nav-link"
                    :title="$t('Notre offre')"
                    >{{ $t('Notre offre') }}</router-link
                >

                <a
                    href="#"
                    class="nav-link with-sublinks"
                    :title="$t('Événements')"
                    @mouseover.stop="linkHoverActive('Événements')"
                >
                    <span @click.prevent="goToPageEvenements()">{{ $t('Événements') }}</span>
                    <svg
                        class="icon-down"
                        width="10"
                        height="7"
                        viewBox="0 0 10 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M-6.10352e-05 1.37916L1.3791 0L4.99994 3.63158L8.62078 0L9.99994 1.37916L4.99994 6.37916L-6.10352e-05 1.37916Z"
                            fill="white"
                        />
                    </svg>

                    <ul class="sublinks" :class="{ 'is-active': linkActive === 'Événements' }">
                        <svg
                            height="14"
                            viewBox="0 0 22 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.5618 0.995983C11.761 -0.0211118 10.2197 -0.0211109 9.4189 0.995984L0.54857 12.2628C-0.484334 13.5748 0.450227 15.5 2.12 15.5L19.8607 15.5C21.5304 15.5 22.465 13.5748 21.4321 12.2628L12.5618 0.995983Z"
                                fill="#373A3F"
                            />
                        </svg>
                        <li v-for="(item, i) in globals.evenementsEntry.data" :key="i">
                            <router-link class="sublink" :to="item.page.jsonUrl">{{
                                item.page.title
                            }}</router-link>
                        </li>
                    </ul>
                </a>
            </nav>
            <router-link :to="{ name: 'Soumission-' + $route.meta.lang }" class="site-btn alternate">
                {{ $t('Soumission') }}
            </router-link>

            <div class="social">
                <a :href="globals.footer.reseaux.facebook" title="Facebook link" target="_blank" rel="noopener noreferrer">
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM22.0846 20.8785V31.7596H17.5825V20.8789H15.3333V17.1293H17.5825V14.878C17.5825 11.819 18.8526 10 22.461 10H25.465V13.7501H23.5873C22.1826 13.7501 22.0897 14.2741 22.0897 15.2521L22.0846 17.1288H25.4863L25.0882 20.8785H22.0846Z"
                            fill="#F2F9F7"
                        />
                    </svg>
                </a>
                <a :href="globals.footer.reseaux.instagram" title="instagram link" target="_blank" rel="noopener noreferrer">
                    <svg
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M17 0C7.6934 0 0.148926 7.54448 0.148926 16.8511C0.148926 26.1576 7.6934 33.7021 17 33.7021C26.3066 33.7021 33.8511 26.1576 33.8511 16.8511C33.8511 7.54448 26.3066 0 17 0ZM13.295 7.91807C14.2536 7.87444 14.56 7.86377 17.0008 7.86377H16.998C19.4395 7.86377 19.7447 7.87444 20.7034 7.91807C21.6601 7.96188 22.3136 8.11335 22.8865 8.3356C23.4782 8.56497 23.9781 8.87204 24.478 9.37196C24.978 9.8715 25.285 10.3729 25.5153 10.964C25.7363 11.5355 25.8879 12.1885 25.9329 13.1453C25.9759 14.104 25.9872 14.4103 25.9872 16.8511C25.9872 19.2919 25.9759 19.5975 25.9329 20.5561C25.8879 21.5125 25.7363 22.1658 25.5153 22.7374C25.285 23.3283 24.978 23.8298 24.478 24.3293C23.9787 24.8292 23.478 25.137 22.8871 25.3666C22.3153 25.5888 21.6614 25.7403 20.7047 25.7841C19.746 25.8278 19.4406 25.8384 16.9996 25.8384C14.559 25.8384 14.2529 25.8278 13.2942 25.7841C12.3377 25.7403 11.6844 25.5888 11.1126 25.3666C10.5218 25.137 10.0204 24.8292 9.52107 24.3293C9.02134 23.8298 8.71427 23.3283 8.48453 22.7372C8.26247 22.1658 8.11099 21.5127 8.06699 20.5559C8.02355 19.5973 8.0127 19.2919 8.0127 16.8511C8.0127 14.4103 8.02393 14.1038 8.06681 13.1451C8.10987 12.1887 8.26153 11.5355 8.48434 10.9638C8.71464 10.3729 9.02171 9.8715 9.52163 9.37196C10.0212 8.87222 10.5226 8.56516 11.1137 8.3356C11.6851 8.11335 12.3382 7.96188 13.295 7.91807Z"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.1943 9.4832C16.3509 9.48296 16.5193 9.48303 16.701 9.48312L17.0005 9.4832C19.4002 9.4832 19.6846 9.49182 20.6322 9.53488C21.5084 9.57495 21.984 9.72137 22.3008 9.84438C22.7202 10.0073 23.0192 10.202 23.3336 10.5166C23.6482 10.8311 23.8429 11.1307 24.0062 11.5501C24.1292 11.8665 24.2758 12.3421 24.3157 13.2184C24.3587 14.1658 24.3681 14.4504 24.3681 16.8489C24.3681 19.2474 24.3587 19.532 24.3157 20.4794C24.2756 21.3556 24.1292 21.8312 24.0062 22.1477C23.8433 22.5671 23.6482 22.8657 23.3336 23.1801C23.0191 23.4946 22.7204 23.6894 22.3008 23.8523C21.9844 23.9758 21.5084 24.1219 20.6322 24.1619C19.6848 24.205 19.4002 24.2144 17.0005 24.2144C14.6007 24.2144 14.3163 24.205 13.3689 24.1619C12.4927 24.1215 12.0171 23.9751 11.7001 23.8521C11.2807 23.6892 10.9811 23.4944 10.6665 23.1799C10.352 22.8653 10.1573 22.5665 9.99399 22.1469C9.87098 21.8305 9.72437 21.3549 9.68449 20.4786C9.64143 19.5312 9.63281 19.2466 9.63281 16.8466C9.63281 14.4466 9.64143 14.1635 9.68449 13.2161C9.72456 12.3399 9.87098 11.8643 9.99399 11.5475C10.1569 11.1281 10.352 10.8285 10.6665 10.5139C10.9811 10.1994 11.2807 10.0047 11.7001 9.84139C12.0169 9.71781 12.4927 9.57177 13.3689 9.53151C14.198 9.49406 14.5193 9.48283 16.1943 9.48096V9.4832ZM21.798 10.9755C21.2026 10.9755 20.7195 11.458 20.7195 12.0536C20.7195 12.649 21.2026 13.1321 21.798 13.1321C22.3934 13.1321 22.8764 12.649 22.8764 12.0536C22.8764 11.4582 22.3934 10.9751 21.798 10.9751V10.9755ZM12.3852 16.8512C12.3852 14.3024 14.4516 12.2359 17.0004 12.2358C19.5492 12.2358 21.6152 14.3024 21.6152 16.8512C21.6152 19.4 19.5494 21.4656 17.0005 21.4656C14.4517 21.4656 12.3852 19.4 12.3852 16.8512Z"
                        />
                        <path
                            d="M17.0007 13.8555C18.6551 13.8555 19.9964 15.1966 19.9964 16.8512C19.9964 18.5057 18.6551 19.847 17.0007 19.847C15.3461 19.847 14.0049 18.5057 14.0049 16.8512C14.0049 15.1966 15.3461 13.8555 17.0007 13.8555Z"
                        />
                    </svg>
                </a>
                <a :href="globals.footer.reseaux.linkedin" title="LinkedIn link" target="_blank" rel="noopener noreferrer">
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM9.60137 16.5649H14.1338V30.183H9.60137V16.5649ZM14.4323 12.3522C14.4029 11.017 13.448 10 11.8975 10C10.347 10 9.3333 11.017 9.3333 12.3522C9.3333 13.6598 10.317 14.7061 11.8387 14.7061H11.8676C13.448 14.7061 14.4323 13.6598 14.4323 12.3522ZM25.2609 16.2451C28.2435 16.2451 30.4795 18.1919 30.4795 22.3748L30.4793 30.183H25.947V22.8973C25.947 21.0673 25.2912 19.8185 23.6504 19.8185C22.3982 19.8185 21.6524 20.6604 21.3248 21.4735C21.205 21.7649 21.1756 22.1709 21.1756 22.5779V30.1834H16.6426C16.6426 30.1834 16.7023 17.8431 16.6426 16.5653H21.1756V18.4941C21.7771 17.5669 22.8545 16.2451 25.2609 16.2451Z"
                            fill="#F2F9F7"
                        />
                    </svg>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'NavBar',

    data() {
        return {
            linkActive: '',
            delayer: null,
            mobileMenuOpen: false,
        }
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['content', 'globals']),
    },

    methods: {
        goToPageEvenements() {
            this.$router.push({ name: 'EvenementsListing-' + this.$route.meta.lang })
        },
        closeAfterClick() {
            setTimeout(() => {
                this.mobileMenuOpen = false
            }, 55)
        },
        linkHoverActive(slug) {
            clearTimeout(this.delayer)
            this.delayer = null

            this.linkActive = slug
        },
        delayCloseActiveLink() {
            clearTimeout(this.delayer)
            this.delayer = null

            this.delayer = setTimeout(() => {
                if (document.querySelector('.sublinks.is-active')) {
                    document.querySelector('.sublinks.is-active').classList.remove('is-active')
                    this.linkActive = ''
                }
            }, 599)
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
