<template>
    <footer
        class="main-footer"
        :class="$route.meta.footer ? $route.meta.footer : ''"
        v-if="globalsIsLoaded"
    >
        <section class="contact site-max-width">
            <div class="content">
                <h3 class="small-title" data-inview="fadeInUp" data-delay="400">
                    <span v-html="globals.footer.simpleTexte"></span>
                </h3>
                <div class="infos">
                    <p class="small-text" data-inview="fadeInUp" data-delay="700">
                        <a :href="'tel:' + globals.footer.informationsContact.telephone">
                            <svg
                                width="20"
                                height="25"
                                viewBox="0 0 20 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.61695 0H17.3831C18.8167 0 20 1.20858 20 2.67283V32.3272C20 33.7914 18.8167 35 17.3831 35H2.61695C1.18331 35 0 33.7914 0 32.3272V2.67283C0 1.20858 1.18331 0 2.61695 0V0ZM8.22423 32.5814C7.85078 32.5814 7.60178 32.3271 7.60178 31.9456C7.60178 31.5642 7.85076 31.3099 8.22423 31.3099H11.8385C12.1504 31.3099 12.4609 31.5642 12.4609 31.9456C12.4609 32.3271 12.149 32.5814 11.8385 32.5814H8.22423ZM1.74414 4.32703V28.8913H18.3172V4.32703H1.74414Z"
                                    fill="#EEE9E2"
                                />
                            </svg>
                            {{ globals.footer.informationsContact.telephone }}
                        </a>
                    </p>
                    <p class="small-text" data-inview="fadeInUp" data-delay="800">
                        <a :href="'mailto:' + globals.footer.informationsContact.courriel">
                            <svg
                                width="25"
                                height="16"
                                viewBox="0 0 25 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.87498 0.000230639C1.50633 0.000230639 1.1682 0.109604 0.878891 0.288688L11.582 9.4904C12.1472 9.97716 12.832 9.97716 13.3984 9.4904L24.1209 0.278842C23.8328 0.100962 23.491 0 23.1248 0L1.87498 0.000230639ZM0.107422 1.25021C0.042725 1.43771 0 1.63602 0 1.84636V14.1539C0 15.1767 0.836188 16 1.875 16H23.125C24.1638 16 25 15.1767 25 14.1539V1.84636C25 1.63603 24.9573 1.43772 24.8926 1.25021L14.2188 10.423C13.224 11.2775 11.7555 11.2691 10.762 10.4134L0.107422 1.25021Z"
                                    fill="#EEE9E2"
                                />
                            </svg>
                            {{ globals.footer.informationsContact.courriel }}
                        </a>
                    </p>
                    <address class="small-text" data-inview="fadeInUp" data-delay="600">
                        <svg
                            width="25"
                            height="35"
                            viewBox="0 0 25 35"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.4995 17.086C9.75722 17.086 7.53279 14.9077 7.53279 12.2186C7.53279 9.52956 9.75706 7.34973 12.4995 7.34973C15.2433 7.34973 17.4677 9.52952 17.4677 12.2186C17.4677 14.9078 15.2435 17.086 12.4995 17.086ZM24.3039 8.15322C24.0846 7.545 23.8155 6.95357 23.4998 6.38805C23.184 5.821 22.8232 5.27831 22.4203 4.76617C22.0175 4.25552 21.5741 3.77534 21.0951 3.33176C20.6145 2.88666 20.098 2.47813 19.5521 2.11229C19.0015 1.7434 18.4197 1.41873 17.8162 1.14128C17.2033 0.860801 16.5672 0.629102 15.9155 0.450767C15.2482 0.26632 14.5653 0.1398 13.8763 0.0696831C13.0596 -0.0141541 12.2259 -0.0217793 11.4078 0.0437689C10.7156 0.0986432 10.0281 0.211447 9.35616 0.379125C8.69978 0.54223 8.05892 0.760209 7.4383 1.02851C6.82858 1.29222 6.24061 1.6032 5.68218 1.95987C5.12845 2.31199 4.60427 2.70833 4.11274 3.14274C3.62587 3.57414 3.17169 4.04515 2.75796 4.54667C2.3442 5.04663 1.97245 5.58016 1.6427 6.13807C1.3145 6.69903 1.02984 7.28437 0.794986 7.88954C0.760766 7.97643 0.728102 8.06484 0.696995 8.15325C-0.0340497 10.1776 -0.22383 12.4243 0.289482 14.708C1.49649 20.0935 6.03861 24.2733 8.81004 28.9225C9.0558 29.3341 11.3392 32.9239 12.1667 34.7744V34.7759C12.2196 34.907 12.3487 35 12.4996 35C12.652 35 12.7811 34.907 12.834 34.7759V34.7744C13.6615 32.9238 15.9449 29.3342 16.1906 28.9225C18.9624 24.2733 23.5043 20.0935 24.7112 14.708C25.223 12.4245 25.0347 10.1778 24.3037 8.15325"
                                fill="#EEE9E2"
                            />
                        </svg>
                        <a href="https://www.google.com/maps/place/619+Rue+le+Breton,+Longueuil,+QC+J4G+1R9" target="_blank" rel="noopener noreferrer"
                            ><span v-html="globals.footer.informationsContact.adresse"></span
                        ></a>
                    </address>
                </div>
                <div class="reseaux" data-inview="fadeInUp" data-delay="900">
                    <a :href="globals.footer.reseaux.facebook" title="facebook">
                        <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M16.8511 0C7.54448 0 0 7.54448 0 16.8511C0 26.1576 7.54448 33.7021 16.8511 33.7021C26.1576 33.7021 33.7021 26.1576 33.7021 16.8511C33.7021 7.54448 26.1576 0 16.8511 0ZM18.6075 17.5915V26.7594H14.8143V17.5919H12.9192V14.4326H14.8143V12.5357C14.8143 9.95838 15.8844 8.42578 18.9246 8.42578H21.4557V11.5854H19.8736C18.6901 11.5854 18.6118 12.027 18.6118 12.8509L18.6075 14.4322H21.4736L21.1382 17.5915H18.6075Z"
                            />
                        </svg>
                    </a>
                    <a :href="globals.footer.reseaux.instagram" title="instagram">
                        <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17 0C7.6934 0 0.148926 7.54448 0.148926 16.8511C0.148926 26.1576 7.6934 33.7021 17 33.7021C26.3066 33.7021 33.8511 26.1576 33.8511 16.8511C33.8511 7.54448 26.3066 0 17 0ZM13.295 7.91807C14.2536 7.87444 14.56 7.86377 17.0008 7.86377H16.998C19.4395 7.86377 19.7447 7.87444 20.7034 7.91807C21.6601 7.96188 22.3136 8.11335 22.8865 8.3356C23.4782 8.56497 23.9781 8.87204 24.478 9.37196C24.978 9.8715 25.285 10.3729 25.5153 10.964C25.7363 11.5355 25.8879 12.1885 25.9329 13.1453C25.9759 14.104 25.9872 14.4103 25.9872 16.8511C25.9872 19.2919 25.9759 19.5975 25.9329 20.5561C25.8879 21.5125 25.7363 22.1658 25.5153 22.7374C25.285 23.3283 24.978 23.8298 24.478 24.3293C23.9787 24.8292 23.478 25.137 22.8871 25.3666C22.3153 25.5888 21.6614 25.7403 20.7047 25.7841C19.746 25.8278 19.4406 25.8384 16.9996 25.8384C14.559 25.8384 14.2529 25.8278 13.2942 25.7841C12.3377 25.7403 11.6844 25.5888 11.1126 25.3666C10.5218 25.137 10.0204 24.8292 9.52107 24.3293C9.02134 23.8298 8.71427 23.3283 8.48453 22.7372C8.26247 22.1658 8.11099 21.5127 8.06699 20.5559C8.02355 19.5973 8.0127 19.2919 8.0127 16.8511C8.0127 14.4103 8.02393 14.1038 8.06681 13.1451C8.10987 12.1887 8.26153 11.5355 8.48434 10.9638C8.71464 10.3729 9.02171 9.8715 9.52163 9.37196C10.0212 8.87222 10.5226 8.56516 11.1137 8.3356C11.6851 8.11335 12.3382 7.96188 13.295 7.91807Z"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M16.1943 9.4832C16.3509 9.48296 16.5193 9.48303 16.701 9.48312L17.0005 9.4832C19.4002 9.4832 19.6846 9.49182 20.6322 9.53488C21.5084 9.57495 21.984 9.72137 22.3008 9.84438C22.7202 10.0073 23.0192 10.202 23.3336 10.5166C23.6482 10.8311 23.8429 11.1307 24.0062 11.5501C24.1292 11.8665 24.2758 12.3421 24.3157 13.2184C24.3587 14.1658 24.3681 14.4504 24.3681 16.8489C24.3681 19.2474 24.3587 19.532 24.3157 20.4794C24.2756 21.3556 24.1292 21.8312 24.0062 22.1477C23.8433 22.5671 23.6482 22.8657 23.3336 23.1801C23.0191 23.4946 22.7204 23.6894 22.3008 23.8523C21.9844 23.9758 21.5084 24.1219 20.6322 24.1619C19.6848 24.205 19.4002 24.2144 17.0005 24.2144C14.6007 24.2144 14.3163 24.205 13.3689 24.1619C12.4927 24.1215 12.0171 23.9751 11.7001 23.8521C11.2807 23.6892 10.9811 23.4944 10.6665 23.1799C10.352 22.8653 10.1573 22.5665 9.99399 22.1469C9.87098 21.8305 9.72437 21.3549 9.68449 20.4786C9.64143 19.5312 9.63281 19.2466 9.63281 16.8466C9.63281 14.4466 9.64143 14.1635 9.68449 13.2161C9.72456 12.3399 9.87098 11.8643 9.99399 11.5475C10.1569 11.1281 10.352 10.8285 10.6665 10.5139C10.9811 10.1994 11.2807 10.0047 11.7001 9.84139C12.0169 9.71781 12.4927 9.57177 13.3689 9.53151C14.198 9.49406 14.5193 9.48283 16.1943 9.48096V9.4832ZM21.798 10.9755C21.2026 10.9755 20.7195 11.458 20.7195 12.0536C20.7195 12.649 21.2026 13.1321 21.798 13.1321C22.3934 13.1321 22.8764 12.649 22.8764 12.0536C22.8764 11.4582 22.3934 10.9751 21.798 10.9751V10.9755ZM12.3852 16.8512C12.3852 14.3024 14.4516 12.2359 17.0004 12.2358C19.5492 12.2358 21.6152 14.3024 21.6152 16.8512C21.6152 19.4 19.5494 21.4656 17.0005 21.4656C14.4517 21.4656 12.3852 19.4 12.3852 16.8512Z"
                            />
                            <path
                                d="M17.0007 13.8555C18.6551 13.8555 19.9964 15.1966 19.9964 16.8512C19.9964 18.5057 18.6551 19.847 17.0007 19.847C15.3461 19.847 14.0049 18.5057 14.0049 16.8512C14.0049 15.1966 15.3461 13.8555 17.0007 13.8555Z"
                            />
                        </svg>
                    </a>
                    <a :href="globals.footer.reseaux.linkedin" title="linkedin">
                        <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.1489 0C7.84233 0 0.297852 7.54448 0.297852 16.8511C0.297852 26.1576 7.84233 33.7021 17.1489 33.7021C26.4555 33.7021 34 26.1576 34 16.8511C34 7.54448 26.4555 0 17.1489 0ZM8.38745 13.957H12.2063V25.431H8.38745V13.957ZM12.4578 10.4077C12.433 9.28266 11.6285 8.42578 10.3221 8.42578C9.0157 8.42578 8.16162 9.28266 8.16162 10.4077C8.16162 11.5094 8.99045 12.3909 10.2725 12.3909H10.2969C11.6285 12.3909 12.4578 11.5094 12.4578 10.4077ZM21.5815 13.6875C24.0945 13.6875 25.9784 15.3278 25.9784 18.8521L25.9782 25.4309H22.1596V19.2923C22.1596 17.7504 21.607 16.6983 20.2245 16.6983C19.1695 16.6983 18.5411 17.4076 18.2651 18.0927C18.1641 18.3382 18.1393 18.6803 18.1393 19.0232V25.4312H14.3201C14.3201 25.4312 14.3704 15.0339 14.3201 13.9572H18.1393V15.5824C18.6462 14.8012 19.554 13.6875 21.5815 13.6875Z"
                            />
                        </svg>
                    </a>
                </div>
            </div>
        </section>
        <section class="legals site-max-width">
            <div class="left" data-inview="fadeInUp" data-delay="200">
                <p class="small-text"><span v-html="globals.footer.simplePhrase"></span></p>
                <p class="small-text">
                    <router-link :to="{ name: 'Politique-' + $route.meta.lang }" title="Politique de confidentialité"
                        >{{ $t('Politique de confidentialité') }}</router-link
                    >
                </p>
            </div>
            <div class="right" data-inview="fadeInUp" data-delay="400">
                <p class="small-text hf"><img src="@/assets/img/logo-hf.png" alt="HF" /></p>
                <a href="https://elmire.ca/" target="_blank" rel="noopener noreferrer" class="small-text elmire">
                    Conception Elmire <img src="@/assets/img/logo-elmire.png" alt="Elmire" />
                </a>
            </div>
        </section>
    </footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'Footer',

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
