<template>
    <div id="app">
        <NavBar />
        <div class="main-wrapper">
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>
        </div>
        <Footer />

        <div class="site-loader">
            <div class="logo-wrap site-max-width">
                <img src="@/assets/img/logo-dock619.png" alt="Dock619" />
            </div>

            <PageLoader class="svg-loader" />
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapGetters } from 'vuex'

import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import PageLoader from '@/components/PageLoader'

export default {
    computed: {
        ...mapGetters(['seo']),
    },

    components: {
        NavBar,
        Footer,
        PageLoader,
    },

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
        }
    },

    mounted() {
        this.loaderFadeOut()

        let direction = ''
        let last_known_scroll_position = 0
        let ticking = false

        function checkScrollPos(scroll_pos) {
            document.querySelector('html').setAttribute('data-direction', direction)

            if (scroll_pos >= 155) {
                document.querySelector('body').classList.add('hide-nav')
            } else {
                document.querySelector('body').classList.remove('hide-nav')
            }
        }

        window.addEventListener(
            'scroll',
            () => {
                const top = window.scrollY

                if (top >= 1) {
                    document.querySelector('body').classList.add('nav-is-fixed')
                } else {
                    document.querySelector('body').classList.remove('nav-is-fixed')
                }

                if (last_known_scroll_position < window.scrollY) {
                    direction = 'down'
                } else if (last_known_scroll_position > window.scrollY + 25) {
                    direction = 'up'
                }

                last_known_scroll_position = window.scrollY

                if (!ticking) {
                    window.requestAnimationFrame(function () {
                        checkScrollPos(last_known_scroll_position)
                        ticking = false
                    })

                    ticking = true
                }
            },
            false
        )
    },

    methods: {
        loaderFadeOut() {
            gsap.to('.logo-wrap', {
                scale: 0.375,
                opacity: 0,
                delay: 3.5,
                duration: 1.175,
                ease: 'power4.out',
                onComplete: () => {
                    [].slice.call(document.querySelectorAll('.hero img')).forEach($img => {
                        $img.style.transform = 'scale(1)'
                    })

                    gsap.to('.site-loader', {
                        height: '0',
                        delay: 0,
                        duration: 2,
                        ease: 'power4.out',
                        onComplete: () => {
                            document.querySelector('.site-loader').style.display = 'none'

                            const $eventsGrid = document.querySelector('.evenements-listing')
                            if ($eventsGrid) {
                                $eventsGrid.classList.add('is-in-view')
                            }
                        },
                    })

                    // gsap.to('.page-wrapper', {
                    //     scale: '1',
                    //     delay: 0,
                    //     duration: 2.5,
                    //     ease: 'power4.out'
                    // })
                },
            })

            gsap.to('.svg-loader', {
                opacity: 0,
                delay: 3,
                duration: 1.25,
                ease: 'power4.out',
            })
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

.site-loader .svg-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -15%);
}

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.555s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.35, 0, 0.22, 1);
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
    transform: scale(1.0125) rotate(0.525deg);
}
</style>
